import {
    Button,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import React, { ChangeEvent, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { matchPath, useHistory } from "react-router-dom";
import { KeyboardKeys } from "../constants/enums";
import { Restaurant } from "../generated-interfaces/graphql";
import { useLoadMenu } from "../hooks";
import { MENU_EDITOR_ROUTES } from "../pages/menu-editor/menu-editor-routes";
import { resetMenuVersionData } from "../reducers/menuReducer";
import {
    getRestaurantInfoFromHoneyAdminAction,
    RESTAURANT_ACTIONS,
} from "../reducers/restaurantReducer";
import {
    restaurantInfoSelector,
    restaurantsByUserRoleSelector,
    selectedStageSelector,
} from "../selectors/restaurant";
import { menuStagesMapping } from "../types/menuVersion";
import { BannerColorMapping, stagesList } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
    button: {
        color: "#E0E0E0",
        borderColor: "#203F53",
        margin: theme.spacing(1, 2),
    },
    stageButton: ({ selectedStage }: { selectedStage: string }) => {
        const color = BannerColorMapping[selectedStage];
        const styles = {
            backgroundColor: `${color}`,
            color: theme.palette.getContrastText(color),
            margin: theme.spacing(1, 2),
        };

        return {
            ...styles,
            "&:hover": styles,
        };
    },
    dropdownIcon: {
        color: "#e0e0e0",
    },
    menu: {
        "& .MuiMenu-paper": {
            width: "255px",
            maxHeight: "230px",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    input: {
        color: "#616161",
    },
    name: {
        margin: "0 auto",
        color: theme.palette.common.white,
    },
}));

interface IRestaurantProps {
    isMouseHovered?: boolean;
    pathName: string;
    restaurantId: string;
}

const RestaurantPicker = (props: IRestaurantProps) => {
    const { pathName, restaurantId } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    // To align max-with with mui theme md =1279.95
    const isStaffTablet = useMediaQuery({ query: "(max-width: 1279.95px)" });
    const restaurantsByUserRole = useSelector(restaurantsByUserRoleSelector);
    const selectedRestaurant = useSelector(restaurantInfoSelector);
    const selectedStage = useSelector(selectedStageSelector);
    const classes = useStyles({ selectedStage });
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [
        anchorStageEl,
        setAnchorStageEl,
    ] = useState<HTMLButtonElement | null>(null);
    const [restaurantsMap, setRestaurantsMap] = useState<
        Record<string, Restaurant>
    >({});
    const [search, setSearch] = useState<string>("");
    const [isDetailPage, setIsDetailPage] = useState<boolean>(false);
    const menuEditorRoutes = MENU_EDITOR_ROUTES(
        selectedRestaurant?.restaurantCode || ""
    );
    const filterRef = React.useRef<HTMLInputElement>();
    const { loadMenu } = useLoadMenu();

    useEffect(() => {
        setRestaurantsMap(
            restaurantsByUserRole.reduce((a, c) => {
                c.restaurants.forEach((r) => {
                    if (!(r.restaurantCode in a)) {
                        a[r.restaurantCode] = r;
                    }
                });
                return a;
            }, {} as Record<string, Restaurant>)
        );
        setIsDetailPage(
            !!Object.values(menuEditorRoutes).find((route) => {
                if (!route.rootTab) {
                    return matchPath(pathName, {
                        path: route.path,
                        exact: true,
                    });
                }
                return false;
            })
        );
    }, [restaurantsByUserRole, pathName]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStageClose = () => {
        setAnchorStageEl(null);
    };

    const handleRestaurantChange = (updatedrestaurantCode: string) => {
        if (isDetailPage) {
            history.push(
                MENU_EDITOR_ROUTES(updatedrestaurantCode)["overview"].path
            );
        } else {
            const pathList = pathName.split("/");
            pathList[1] = updatedrestaurantCode || "";
            history.push(pathList.join("/"));
        }
    };

    const restaurantsEntries = Object.entries(restaurantsMap);
    const showSearchInput = restaurantsEntries.length > 5;
    const showDropdown = restaurantsEntries.length > 1;
    const filteredRestaurantsEntries =
        showSearchInput && search
            ? restaurantsEntries.filter((item) => {
                  return item[1].restaurantName
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase());
              })
            : restaurantsEntries;

    useEffect(() => {
        if (selectedRestaurant?.restaurantCode) {
            dispatch(
                getRestaurantInfoFromHoneyAdminAction(
                    selectedRestaurant?.restaurantCode
                )
            );
        }
        if (selectedRestaurant?.restaurantCode !== restaurantId) {
            dispatch({
                type: RESTAURANT_ACTIONS.selectRestaurant.toString(),
                payload: restaurantId,
            });
        }
    }, [dispatch]);

    if (!showDropdown && (!isStaffTablet || props.isMouseHovered)) {
        return (
            <Typography className={classes.name} variant="subtitle1">
                {selectedRestaurant?.restaurantName}
            </Typography>
        );
    }

    const handleTextFieldKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (
            ![
                KeyboardKeys.ARROW_DOWN,
                KeyboardKeys.ARROW_UP,
                KeyboardKeys.HOME,
                KeyboardKeys.END,
            ].includes(e.key as KeyboardKeys)
        ) {
            e.stopPropagation();
        }
    };

    const moveFocusToInput = (e: React.KeyboardEvent<HTMLLIElement>) => {
        if (
            [KeyboardKeys.TAB, KeyboardKeys.ARROW_RIGHT].includes(
                e.key as KeyboardKeys
            )
        ) {
            e.stopPropagation();
            e.preventDefault();
            if (filterRef?.current) {
                filterRef.current.focus();
            }
        }
    };

    return (
        <>
            {!isStaffTablet || props.isMouseHovered ? (
                <>
                    <Button
                        disableElevation
                        aria-controls="restaurant-picker"
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        className={classes.button}
                        variant="outlined"
                    >
                        {selectedRestaurant?.restaurantName}
                        <ArrowDropDownIcon />
                    </Button>
                    <Button
                        disableElevation
                        aria-controls="stage-picker"
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorStageEl(event.currentTarget);
                        }}
                        className={classes.stageButton}
                        variant="outlined"
                    >
                        {menuStagesMapping[selectedStage]}
                        <ArrowDropDownIcon />
                    </Button>
                </>
            ) : (
                <>
                    <IconButton
                        className={classes.dropdownIcon}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                    <IconButton
                        className={classes.dropdownIcon}
                        onClick={(event) => {
                            setAnchorStageEl(event.currentTarget);
                        }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                </>
            )}
            <Menu
                id="restaurant-picker"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
            >
                {showSearchInput && (
                    <MenuItem onKeyDown={moveFocusToInput}>
                        <TextField
                            className={classes.input}
                            value={search}
                            onKeyDown={handleTextFieldKeyDown}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                setSearch(event.currentTarget.value);
                            }}
                            InputProps={{
                                startAdornment: <SearchIcon />,
                                disableUnderline: true,
                            }}
                        />
                    </MenuItem>
                )}
                {filteredRestaurantsEntries.map(
                    ([restaurantCode, restaurant]) => {
                        return (
                            <MenuItem
                                key={restaurantCode}
                                onClick={() => {
                                    if (
                                        selectedRestaurant?.restaurantCode !==
                                        restaurantCode
                                    ) {
                                        handleRestaurantChange(restaurantCode);
                                        dispatch({
                                            type: RESTAURANT_ACTIONS.selectRestaurant.toString(),
                                            payload: restaurantCode,
                                        });
                                    }
                                    handleClose();
                                }}
                            >
                                <Tooltip
                                    title={restaurant.restaurantCode}
                                    arrow
                                >
                                    <Typography>
                                        {restaurant.restaurantName}
                                    </Typography>
                                </Tooltip>
                            </MenuItem>
                        );
                    }
                )}
            </Menu>
            <Menu
                id="stage-picker"
                anchorEl={anchorStageEl}
                keepMounted
                open={Boolean(anchorStageEl)}
                onClose={handleStageClose}
                className={classes.menu}
            >
                {stagesList.map(({ label, value }) => (
                    <MenuItem
                        key={value}
                        onClick={() => {
                            dispatch({
                                type: RESTAURANT_ACTIONS.selectStage.toString(),
                                payload: value,
                            });

                            loadMenu({
                                stage: value,
                                successCallback: () => {
                                    console.log("successCallback");
                                    dispatch(resetMenuVersionData());
                                },
                            });
                            handleStageClose();
                        }}
                    >
                        <Tooltip title={label} arrow>
                            <Typography>{label}</Typography>
                        </Tooltip>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default memo(RestaurantPicker);
