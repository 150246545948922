import { Box, makeStyles, Tab, Theme, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { ChangeEvent, FC, memo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { CircularLoading } from "../../components/CircularLoading";
import { useCustomHistory } from "../../hooks";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { DATA_TABLE_DEFAULT_STYLING } from "../../utils/data-tables";
import { isEntitySavingSelector } from "../../utils/menu";
import {
    MenuActivityVersionList,
    MenuRequestStatus,
    MenuVersionList,
} from "./";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
    container: {
        width: "100%",
    },
    tabContainer: { borderBottom: 1, borderColor: "divider" },
    childComponent: {
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.background.paper,
    },
    tabsList: {
        backgroundColor: theme.palette.background.default,
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
    },
    tab: {
        minWidth: theme.spacing(17.5),
    },
}));

const menuVersionList: {
    value: string;
    header: string;
    tabLabel: string;
    component: FC;
}[] = [
    {
        value: "versions",
        header: "Menu Version List",
        tabLabel: "Version List",
        component: MenuVersionList,
    },
    {
        value: "version-history",
        header: "Menu Version Activity History",
        tabLabel: "Version Activity History",
        component: MenuActivityVersionList,
    },
    {
        value: "request-status",
        header: "Request Status",
        tabLabel: "Request Status",
        component: MenuRequestStatus,
    },
];

const MenuVersion = () => {
    const classes = useStyles();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { menuType } = useParams<{ menuType: string }>();
    const { pushToHistory } = useCustomHistory();
    const location = useLocation();
    const defaultTab = menuVersionList[0]?.value;
    const isSavingEntity = useSelector(isEntitySavingSelector);

    useEffect(() => {
        if (restaurantCode && !menuType) {
            pushToHistory(`/${restaurantCode}/menu-version/${defaultTab}`);
        }
    }, [restaurantCode, pushToHistory, location, menuType, defaultTab]);

    const handleChange = useCallback(
        (event: ChangeEvent<{}>, newValue: any) => {
            pushToHistory(`/${restaurantCode}/menu-version/${newValue}`);
        },
        [pushToHistory, restaurantCode]
    );

    return (
        <Box className={classes.container}>
            {isSavingEntity && <CircularLoading />}
            <TabContext value={menuType || defaultTab}>
                <Box className={classes.tabContainer}>
                    <TabList
                        scrollButtons="auto"
                        className={classes.tabsList}
                        indicatorColor="secondary"
                        aria-label="Menu version navigation tabs"
                        onChange={handleChange}
                    >
                        {menuVersionList.map(({ value, tabLabel }) => (
                            <Tab
                                className={classes.tab}
                                label={tabLabel}
                                value={value}
                                key={value}
                            />
                        ))}
                    </TabList>
                </Box>
                {menuVersionList.map(
                    ({ value, component: Component, header }) => (
                        <TabPanel
                            value={value}
                            className={classes.childComponent}
                            key={value}
                        >
                            <Typography
                                className={classes.header}
                                variant="h4"
                                color="primary"
                            >
                                {header}
                            </Typography>
                            <Component />
                        </TabPanel>
                    )
                )}
            </TabContext>
        </Box>
    );
};

export default memo(MenuVersion);
