import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { memo, MouseEvent, useCallback, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import CommitDialog from "../../components/menuVersion/CommitDialog";
import { useCommitDialog, useCustomHistory } from "../../hooks";
import useRestoreDB from "../../hooks/useRestoreDB.hook";
import { promoteMenuVersion } from "../../reducers/menuReducer";
import {
    selectedRestaurantCodeSelector,
    selectedStageSelector,
} from "../../selectors/restaurant";
import { IMenuHistory, MenuStages } from "../../types/menuVersion";
import { TIME_OUT } from "../../utils/constants";
import { MENU_EDITOR_ROUTES } from "../menu-editor/menu-editor-routes";

const MenuHistoryItemRowActions = ({ data }: { data: IMenuHistory }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { commitId, stage, isActive } = data;
    const dispatch = useDispatch();
    const alert = useAlert();
    const selectedStage = useSelector(selectedStageSelector);
    const upperCaseStage = stage?.toUpperCase();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    const handleMenuClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget),
        []
    );

    const handleMenuClose = useCallback(() => setAnchorEl(null), []);

    const menuEditorRoutes = MENU_EDITOR_ROUTES(restaurantCode || "");
    const { open, setIsDialogDisabled, toggle } = useCommitDialog();
    const { pushToHistory } = useCustomHistory();
    const { restoreDB } = useRestoreDB();

    const onCommitSuccess = useCallback(() => {
        restoreDB({ commitId });
    }, [commitId, restoreDB]);

    const handlePromote = useCallback(
        (stage?: MenuStages) => {
            const { PLAYGROUND, PRELIVE } = MenuStages;
            const updatedStage = stage === PLAYGROUND ? stage : PRELIVE;
            handleMenuClose();
            dispatch(
                promoteMenuVersion({
                    commitId,
                    stage: updatedStage,
                    successCallback: () => {
                        alert.success(
                            <div>Menu version is successfully promoted</div>,
                            {
                                timeout: TIME_OUT,
                            }
                        );
                    },
                    errorCallback: (error: any) => {
                        alert.error(
                            <div>
                                <span>Error promoting menu version</span>
                                <div>{error?.message}</div>
                            </div>,
                            {
                                timeout: TIME_OUT,
                            }
                        );
                    },
                })
            );
        },
        [dispatch, alert, commitId, handleMenuClose]
    );

    const handleView = useCallback(() => {
        handleMenuClose();
        pushToHistory(
            Object.values(menuEditorRoutes)[0].path,
            `?version=${commitId}`
        );
    }, [commitId, handleMenuClose, pushToHistory, menuEditorRoutes]);

    const handleRestore = useCallback(() => {
        handleMenuClose();
        toggle();
    }, [handleMenuClose, toggle]);

    return (
        data && (
            <div>
                <>
                    <IconButton
                        onClick={handleMenuClick}
                        aria-controls={`more-options-${data.id}`}
                    >
                        <Tooltip title="More options" aria-label="More options">
                            <MoreVertIcon />
                        </Tooltip>
                    </IconButton>
                    <CommitDialog
                        toggle={toggle}
                        open={open}
                        setIsDialogDisabled={setIsDialogDisabled}
                        onCommitSuccess={onCommitSuccess}
                    />
                    <Menu
                        id={`more-options-${data.id}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleView}>Load To View</MenuItem>
                        {!(isActive && upperCaseStage === MenuStages.LIVE) && (
                            <MenuItem
                                onClick={() =>
                                    handlePromote(upperCaseStage as MenuStages)
                                }
                            >
                                {upperCaseStage === MenuStages.PLAYGROUND
                                    ? "Verify"
                                    : "Publish to Prod"}
                            </MenuItem>
                        )}

                        {selectedStage !== MenuStages.LIVE && (
                            <MenuItem onClick={handleRestore}>
                                Restore To Edit
                            </MenuItem>
                        )}
                    </Menu>
                </>
            </div>
        )
    );
};

export default memo(MenuHistoryItemRowActions);
