import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    Category,
    InputProperties,
    MenuItem,
    MenuItemSetting,
    MenuOverride,
    ModifierGroup,
    NewMenuItemSettingInput,
    PosProperties,
    Query,
    TimePeriod,
    UpdateCategoriesSortOrderInput,
    UpdateMenuItemAvailabilityInput,
    UpdateMenuItemSettingInput,
} from "../generated-interfaces/graphql";
import {
    CategoryType,
    IDuplicatePayload,
    MenuItemType,
    ModifierGroupType,
    TimePeriodInputType,
} from "../types/menu";
import {
    IFetchMenuVersionActionType,
    IMenuHistory,
    IMenuVersionActivity,
    IMenuVersionRequestStatus,
    IPromoteMenuVersionActionType,
    IRestoreDBActionType,
} from "../types/menuVersion";
import { toRecord } from "../utils/menu";
import {
    CallbackFunction,
    CreateMenuVersion,
    DeleteItemType,
    GetIngestedDataType,
} from "../utils/types";
import { USER_ACTIONS } from "./userReducer";

export interface MenuState {
    didInitialLoad: boolean;
    isSavingEntity: boolean;
    isUploadingImage: boolean;
    menuItems: Record<string, MenuItem>;
    modifierGroups: Record<string, ModifierGroup>;
    categories: Record<string, Category>;
    timePeriods: Record<string, TimePeriod>;
    menuOverrides: Record<string, MenuOverride>;
    menuItemSettings: Record<string, MenuItemSetting>;
    posProperties: Record<string, PosProperties>;
    voiceProperties: Record<string, InputProperties[]>;
    menuVersionRequestStatus: IMenuVersionRequestStatus[];
    restorationInProgress: boolean;
    stagesOverview: IMenuHistory[];
    versionList: IMenuHistory[];
    menuVersionActivity: IMenuVersionActivity[];
    latestVersionInfo: IMenuHistory;
}

const initialState: MenuState = {
    didInitialLoad: false,
    isSavingEntity: false,
    isUploadingImage: false,
    menuItems: {},
    modifierGroups: {},
    categories: {},
    timePeriods: {},
    menuOverrides: {},
    menuItemSettings: {},
    posProperties: {},
    voiceProperties: {},
    menuVersionRequestStatus: [],
    restorationInProgress: false,
    stagesOverview: [],
    versionList: [],
    menuVersionActivity: [],
    latestVersionInfo: {} as IMenuHistory,
};

export const getMenuItems = createAction<CallbackFunction | undefined>(
    "GET_MENU_ITEMS"
);
export const createMenuItem = createAction<
    Omit<MenuItemType, "restaurantCode" | "id">
>("CREATE_MENU_ITEM");
export const updateMenuItem = createAction<
    Omit<MenuItemType, "restaurantCode">
>("UPDATE_MENU_ITEM");
export const createModifierGroup = createAction<ModifierGroupType>(
    "CREATE_MODIFIER_GROUP"
);
export const updateModifierGroup = createAction<ModifierGroupType>(
    "UPDATE_MODIFIER_GROUP"
);
export const createCategory = createAction<CategoryType>("CREATE_CATEGORY");
export const updateCategory = createAction<CategoryType>("UPDATE_CATEGORY");
export const duplicateCategory = createAction<IDuplicatePayload>(
    "DUPLICATE_CATEGORY"
);
export const deleteMenuItem = createAction<DeleteItemType>("DELETE_MENU_ITEM");
export const duplicateMenuItem = createAction<IDuplicatePayload>(
    "DUPLICATE_MENU_ITEM"
);
export const deleteCategory = createAction<DeleteItemType>("DELETE_CATEGORY");
export const deleteModifierGroup = createAction<DeleteItemType>(
    "DELETE_MODIFIER_GROUP"
);
export const duplicateModifierGroup = createAction<IDuplicatePayload>(
    "DUPLICATE_MODIFIER_GROUP"
);
export const createTimePeriod = createAction<Omit<TimePeriodInputType, "id">>(
    "CREATE_TIME_PERIOD"
);
export const updateTimePeriod = createAction<TimePeriodInputType>(
    "UPDATE_TIME_PERIOD"
);
export const deleteTimePeriod = createAction<DeleteItemType>(
    "DELETE_TIME_PERIOD"
);
export const updateCategoriesForMenu = createAction<
    UpdateCategoriesSortOrderInput & CallbackFunction
>("UPDATE_CATEGORIES_FOR_MENU");
export const createMenuItemSetting = createAction<
    Omit<NewMenuItemSettingInput, "restaurantCode">
>("CREATE_MENU_ITEM_SETTING");
export const updateMenuItemSetting = createAction<
    Omit<UpdateMenuItemSettingInput, "restaurantCode">
>("UPDATE_MENU_ITEM_SETTING");
export const deleteMenuItemSetting = createAction<DeleteItemType>(
    "DELETE_MENU_ITEM_SETTING"
);
export const updateMenuItemAvailability = createAction<UpdateMenuItemAvailabilityInput>(
    "UPDATE_ITEM_AVAILABILITY"
);
export const getVoiceProperties = createAction<
    Partial<GetIngestedDataType> | undefined
>("GET_VOICE_PROPERTIES");
export const createMenuVersion = createAction<CreateMenuVersion>(
    "CREATE_MENU_VERSION"
);
export const fetchMenuCommitStage = createAction("FETCH_MENU_COMMIT_STAGE");
export const fetchActiveMenuCommitStage = createAction(
    "FETCH_ACTIVE_MENU_COMMIT_STAGE"
);
export const reloadCache = createAction<CallbackFunction>("RELOAD_CACHE");
export const promoteMenuVersion = createAction<IPromoteMenuVersionActionType>(
    "PROMOTE_MENU_VERSION"
);
export const fetchMenuVersionRequestStatus = createAction(
    "FETCH_MENU_VERSION_REQUEST_STATUS"
);
export const fetchCommittedMenuVersion = createAction<IFetchMenuVersionActionType>(
    "FETCH_COMMITTED_MENU_VERSION"
);
export const fetchMenuVersionActivity = createAction(
    "FETCH_MENU_VERSION_ACTIVITY"
);
export const restoreDB = createAction<IRestoreDBActionType>("RESTORE_DB");

export const restoreDBStatus = createAction<Partial<CallbackFunction>>(
    "RESTORE_DB_STATUS"
);
export const fetchLatestVersionOnDB = createAction<Partial<CallbackFunction>>(
    "FETCH_LATEST_VERSION_ON_DB"
);
export const resetMenuVersionData = createAction<CallbackFunction | undefined>(
    "RESET_MENU_VERSION_DATA"
);

export const menuState = createSlice({
    name: "menu",
    initialState: initialState,
    reducers: {
        setIsLoadingEntity: (state, action: PayloadAction<boolean>) => {
            state.isSavingEntity = action.payload;
        },
        loadMenuSuccess: (
            state,
            action: PayloadAction<
                { menuItems: Query["menuItems"] } & {
                    modifierGroups: Query["modifierGroups"];
                } & { categories: Query["categories"] } & {
                    timePeriods: Query["timePeriods"];
                } & { menuOverrides: Query["menuOverrides"] } & {
                    menuItemSettings: Query["menuItemSettings"];
                } & { posProperties: Query["posProperties"] }
            >
        ) => {
            const {
                menuItems,
                modifierGroups,
                categories,
                timePeriods,
                menuOverrides,
                menuItemSettings,
                posProperties,
            } = action.payload;
            state.didInitialLoad = true;
            state.menuItems = toRecord(menuItems);
            state.modifierGroups = toRecord(modifierGroups);
            state.categories = toRecord(categories);
            state.timePeriods = toRecord(timePeriods);
            state.menuOverrides = toRecord(menuOverrides);
            state.menuItemSettings = toRecord(menuItemSettings);
            state.posProperties = toRecord(posProperties);
            state.menuVersionRequestStatus = [];
            state.versionList = [];
            state.stagesOverview = [];
            state.menuVersionActivity = [];
        },
        loadVoiceProperties: (
            state,
            action: PayloadAction<{
                voiceProperties: Record<string, InputProperties[]>;
            }>
        ) => {
            state.voiceProperties = { ...action.payload.voiceProperties };
        },
        loadMenuComitStage: (
            state,
            action: PayloadAction<{
                versionList: IMenuHistory[];
            }>
        ) => {
            state.versionList = action.payload.versionList;
        },
        loadActiveMenuComitStage: (
            state,
            action: PayloadAction<{
                stagesOverview: IMenuHistory[];
            }>
        ) => {
            state.stagesOverview = action.payload.stagesOverview;
        },
        loadMenuVersionRequestStatus: (
            state,
            action: PayloadAction<{
                menuVersionRequestStatus: IMenuVersionRequestStatus[];
            }>
        ) => {
            state.menuVersionRequestStatus =
                action.payload.menuVersionRequestStatus;
        },
        updateDidInitialLoad: (state, action: PayloadAction<boolean>) => {
            state.didInitialLoad = action.payload;
        },
        updateRestorationInProgress: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.restorationInProgress = action.payload;
        },
        loadMenuVersionActivity: (
            state,
            action: PayloadAction<{
                menuVersionActivity: IMenuVersionActivity[];
            }>
        ) => {
            state.menuVersionActivity = action.payload.menuVersionActivity;
        },
        updateLatestVersionInfo: (
            state,
            action: PayloadAction<{
                latestVersionInfo: IMenuHistory;
            }>
        ) => {
            state.latestVersionInfo = action.payload.latestVersionInfo;
        },
        updateStateProperty: (state, { payload }: PayloadAction<MenuState>) => {
            return (state = { ...state, ...payload });
        },
    },
    extraReducers: {
        [USER_ACTIONS.logout.toString()]: () => {
            return initialState;
        },
        // [getMenuItems.toString()]: (state, action) => {
        //     state.didInitialLoad = false;
        // }
    },
});

export const MENU_ACTIONS = menuState.actions;
