import { Chip, ChipProps, makeStyles } from "@material-ui/core";
import React, { memo } from "react";

interface ICustomChipProps extends ChipProps {
    customColor?: string;
    fullWidth?: boolean;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    chip: ({ color }: { color: string }) => {
        const chipColor: Record<string, string | number> = {
            marginBottom: spacing(0.625),
            marginRight: spacing(0.625),
        };
        if (color) {
            chipColor["backgroundColor"] = `${color} !important`;
            chipColor["color"] = palette.getContrastText(color);
        }
        return chipColor;
    },
}));

const CustomChip = ({ customColor, fullWidth, ...props }: ICustomChipProps) => {
    const classes = useStyles({ color: customColor || "" });
    return <Chip {...props} className={classes.chip} />;
};

export default memo(CustomChip);
